import {
  LayoutGrid,
  Link,
  CircleHelp,
  Contact,
  EarthLock,
  ReceiptText,
} from "lucide-react";

export function getMenuList(pathname) {
  return [
    {
      groupLabel: "",
      menus: [
        {
          href: "/dashboard",
          label: "Tableau de bord",
          active: pathname.includes("/dashboard"),
          icon: LayoutGrid,
          submenus: [],
        },
      ],
    },
    {
      groupLabel: "",
      menus: [
        {
          href: "/affiliate-links",
          label: "Coin des Investisseurs",
          active: pathname.includes("/affiliate-links"),
          icon: Link,
          submenus: [],
        },
      ],
    },
    {
      groupLabel: "",
      menus: [
        {
          href: "/contact-us",
          label: "Contactez-nous",
          active: pathname.includes("/contact-us"),
          icon: Contact,
          submenus: [],
        },
      ],
    },
    {
      groupLabel: "",
      menus: [
        {
          href: "/faq",
          label: "Questions fréquemment posées",
          active: pathname.includes("/faq"),
          icon: CircleHelp,
          submenus: [],
        },
      ],
    },
    // {
    //   groupLabel: "",
    //   menus: [
    //     {
    //       href: "/privacy-policy",
    //       label: "Privacy Policy",
    //       active: pathname.includes("/privacy-policy"),
    //       icon: EarthLock,
    //       submenus: [],
    //     },
    //   ],
    // },
    // {
    //   groupLabel: "",
    //   menus: [
    //     {
    //       href: "/terms-and-condition",
    //       label: "Terms And Condition",
    //       active: pathname.includes("/terms-and-condition"),
    //       icon: ReceiptText,
    //       submenus: [],
    //     },
    //   ],
    // },
    // {
    //   groupLabel: "Contents",
    //   menus: [
    //     {
    //       href: "",
    //       label: "Posts",
    //       active: pathname.includes("/posts"),
    //       icon: SquarePen,
    //       submenus: [
    //         {
    //           href: "/posts",
    //           label: "All Posts",
    //           active: pathname === "/posts"
    //         },
    //         {
    //           href: "/posts/new",
    //           label: "New Post",
    //           active: pathname === "/posts/new"
    //         }
    //       ]
    //     },
    //     {
    //       href: "/categories",
    //       label: "Categories",
    //       active: pathname.includes("/categories"),
    //       icon: Bookmark,
    //       submenus: []
    //     },
    //     {
    //       href: "/tags",
    //       label: "Tags",
    //       active: pathname.includes("/tags"),
    //       icon: Tag,
    //       submenus: []
    //     }
    //   ]
    // },
    // {
    //   groupLabel: "Settings",
    //   menus: [
    //     {
    //       href: "/users",
    //       label: "Users",
    //       active: pathname.includes("/users"),
    //       icon: Users,
    //       submenus: [],
    //     },
    //     {
    //       href: "/account",
    //       label: "Account",
    //       active: pathname.includes("/account"),
    //       icon: Settings,
    //       submenus: [],
    //     },
    //   ],
    // },
  ];
}
