import React, { useEffect, useState } from "react";
import { ContentLayout } from "../../components/admin-panel/content-layout";
import { Button } from "../../components/ui/button";
import { AffiliateDataTable } from "./AffiliateDataTable";
import { Card } from "../../components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { AffiliateVideoDataTable } from "./AffiliateVideoDataTable";
import PartnerModal from "./PartnerModal";
import LogoIcons2 from "../../assets/images/partner2.svg";
import AffiliateLinksService from "service/affiliateLink.service";
import { useFormik } from "formik";
import * as yup from "yup";
import Toaster from "../../components/ui/toaster";
import User from "../../assets/images/user.png";
import DeleteModel from "../../components/ui/delete-model";
import Loader from "../../assets/images/loader.gif";

const AffiliateLinks = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const [editingPartner, setEditingPartner] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isImageUpdated, setIsImageUpdated] = useState(false);
  const [selectedImage, setSelectedImage] = useState(User);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [partners, setPartners] = useState([
    {
      id: 1,
      name: "Bourso Bank",
      photo: LogoIcons2,
      content:
        "Trade Republic est un courtier allemand offrant une plateforme mobile intuitive pour investir facilement et passivement.",
      links: "http://localhost:3000/affiliate-links",
    },
  ]);
  const [videos, setVideos] = useState([]);

  const handleAddOrUpdatePartner = (partner) => {
    if (editingPartner) {
      setPartners(partners.map((p) => (p.id === partner.id ? partner : p)));
    } else {
      setPartners([...partners, { ...partner, id: partners.length + 1 }]);
    }
    setIsOpen(false);
    setEditingPartner(null);
  };

  const getAllLinks = async () => {
    const type = tab === 1 ? "Partners" : "Video";
    setIsTableLoading(true);
    try {
      const response = await AffiliateLinksService.getAllLinks(
        type,
        page,
        pageSize
      );
      setTotalCount(response.data.totalDocument);
      console.log("getAllLinks", response.data);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsTableLoading(false);
    }
  };

  useEffect(() => {
    getAllLinks();
  }, [tab, page, pageSize]);
  console.log("🚀 ~ validationSchema:yup.object ~ tab:", tab);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      file: "",
      investorsType: "",
      urlLink: "",
      partnerCategory: "",
    },
    validationSchema: yup.object().shape({
      title: yup.string().required("Please enter Title"),
      file: yup.mixed().required("Please upload file"),
      description:
        tab === 1 && yup.string().required("Please enter description"),
      urlLink: yup.string().required("Please add Video URL"),
      partnerCategory:
        tab === 1 && yup.string().required("Please add Category"),
    }),
    onSubmit: async (values) => {
      setSubmitLoading(true);
      const { description, file, title, urlLink, partnerCategory, id } = values;
      try {
        const formData = new FormData();
        isImageUpdated && formData.append("file", file);
        formData.append("description", description);
        formData.append("investorsType", tab === 1 ? "Partners" : "Video");
        formData.append("title", title);
        formData.append("urlLink", urlLink);
        formData.append("partnerCategory", partnerCategory);

        if (!id) {
          const response = await AffiliateLinksService.createInvestIsseurs(
            formData
          );

          Toaster("success", response.data.message);
        } else {
          const response = await AffiliateLinksService.updateInvestIsseurs(
            id,
            tab === 1 ? "Partners" : "Video",
            formData
          );
          Toaster("success", response.data.message);
        }
        handleClose();
        getAllLinks();
        setIsImageUpdated(false);
        setSelectedImage(User);
      } catch (error) {
        Toaster("error", error?.response?.data.message);
      } finally {
        setSubmitLoading(false);
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setIsOpen(false);
    setSelectedImage(User);
    setIsImageUpdated(false);
  };

  const handleDeleteClose = () => {
    setIsDeleteOpen(false);
    setDetails([]);
  };

  const handleTabChange = (data) => {
    setTab(data);

    if (data === 1) {
      formik.setFieldValue("investorsType", "Partners");
    } else {
      formik.setFieldValue("investorsType", "Video");
    }
  };

  const handleModalOpen = async (result, type) => {
    setIsOpen(true);
    if (result) {
      formik.setFieldValue("id", result._id);
      formik.setFieldValue("title", result.title);
      formik.setFieldValue("investorsType", result.investorsType);
      formik.setFieldValue("urlLink", result.urlLink);
      if (type === "Partners") {
        setSelectedImage(result.partnerImage);
        formik.setFieldValue("file", result.partnerImage);
        formik.setFieldValue("description", result.description);
        formik.setFieldValue("partnerCategory", result.partnerCategory);
      } else {
        setSelectedImage(result.videoThumbnail);
        formik.setFieldValue("file", result.videoThumbnail);
      }
    } else {
      formik.resetForm();
    }
  };

  const handleDelete = async (id, type) => {
    setDeleteLoading(true);
    try {
      const response = await AffiliateLinksService.deleteById(id, type);
      Toaster("success", response.data.message);
      getAllLinks();
      setIsDeleteOpen(false);
      setDetails([]);
    } catch (error) {
      Toaster("error", error?.response?.data.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleDeleteOpen = (data) => {
    setIsDeleteOpen(true);
    setDetails(data);
  };

  const handleImageChange = (event) => {
    setIsImageUpdated(true);
    const file = event.target.files[0];
    formik.setFieldValue("file", file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <ContentLayout title="Coin des Investisseurs">
        {isLoading ? (
          <div className="flex justify-center items-center h-[calc(100vh-176px)]">
            <img src={Loader} alt="Loader" className="w-20" />
          </div>
        ) : (
          <>
            <Tabs defaultValue="partners">
              <TabsList className="inline sm:flex justify-start w-full gap-4">
                <TabsTrigger
                  value="partners"
                  className="w-[180px] "
                  onClick={() => handleTabChange(1)}
                >
                  Partenaires
                </TabsTrigger>
                <TabsTrigger
                  value="video"
                  className="w-[180px]"
                  onClick={() => handleTabChange(2)}
                >
                  Video
                </TabsTrigger>
                <div className="w-full flex justify-end">
                  <Button
                    className="sm:max-w-32 w-full mt-4 sm:mt-0"
                    onClick={() => {
                      setEditingPartner(null);
                      handleModalOpen("", tab === 1 ? "Partners" : "Video");
                    }}
                  >
                    + Ajouter
                  </Button>
                </div>
              </TabsList>
              <TabsContent value="partners">
                <div className="mt-4">
                  <AffiliateDataTable
                    partners={data}
                    setPartners={setPartners}
                    setIsOpen={setIsOpen}
                    setEditingPartner={setEditingPartner}
                    handleModalOpen={handleModalOpen}
                    handleDeleteOpen={handleDeleteOpen}
                    totalCount={totalCount}
                  />
                </div>
              </TabsContent>
              <TabsContent value="video">
                <Card className="mt-4">
                  <AffiliateVideoDataTable
                    data={data}
                    setVideos={setVideos}
                    setIsOpen={setIsOpen}
                    handleModalOpen={handleModalOpen}
                    handleDeleteOpen={handleDeleteOpen}
                    totalCount={totalCount}
                    isTableLoading={isTableLoading}
                  />
                </Card>
              </TabsContent>
            </Tabs>
          </>
        )}
        {isOpen && (
          <PartnerModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            partner={editingPartner}
            onSave={handleAddOrUpdatePartner}
            tab={tab}
            formik={formik}
            handleClose={handleClose}
            setIsImageUpdated={setIsImageUpdated}
            handleImageChange={handleImageChange}
            selectedImage={selectedImage}
            submitLoading={submitLoading}
          />
        )}

        {isDeleteOpen && (
          <DeleteModel
            isDeleteOpen={isDeleteOpen}
            setIsDeleteOpen={setIsDeleteOpen}
            handleDeleteClose={handleDeleteClose}
            details={details}
            handleDelete={handleDelete}
            deleteLoading={deleteLoading}
            type="affiliateLinks"
          />
        )}
      </ContentLayout>
    </>
  );
};

export default AffiliateLinks;
