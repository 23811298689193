import React, { useState } from "react";
import Logo from "../../assets/images/logo.svg";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import Toaster from "../../components/ui/toaster";
import { useNavigate } from "react-router";
import AuthenticationService from "service/auth.service";
import { useFormik } from "formik";
import * as yup from "yup";
import { login, setLoggedIn, setToken } from "store/slice/auth";
import { useDispatch } from "react-redux";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const submit = async (values) => {
    const { email, password } = values;

    try {
      const response = await dispatch(login({ email, password })).unwrap();

      if (response) {
        dispatch(setLoggedIn(true));

        dispatch(setToken(response.data?.token));
        window.location.reload();

        localStorage.setItem("isLoggedIn", true);

        navigate("/dashboard");

        Toaster("success", "Login in successfully!");
      }
    } catch (error) {
      Toaster("error", error?.response?.data.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: submit,
  });

  return (
    <div className="flex items-center justify-center h-screen w-full">
      <div className="max-w-3xl w-full px-5">
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 w-full border-input border h-fit rounded-md shadow-lg">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto"
              src={Logo}
              alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
              Connectez-vous à votre compte
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={formik.handleSubmit}>
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="email">Email</Label>
                <Input
                  type="email"
                  id="email"
                  placeholder="Email..."
                  name="email"
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="valierror">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="password">Mot de passe</Label>
                <div className="relative">
                  <Input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Mot de passe..."
                    name="password"
                    onChange={formik.handleChange}
                  />
                  <div
                    className="absolute top-1/2 right-4 -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FaEyeSlash className="text-primary/50" />
                    ) : (
                      <FaEye className="text-primary/50" />
                    )}
                  </div>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="valierror">{formik.errors.password}</div>
                ) : null}
              </div>
              <div className="mt-4">
                <Button type="submit" className="w-full">
                  Se connecter
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
