import React from "react";
import { Navigate, useRoutes } from "react-router";
import { lazy } from "react";
import Dashboard from "pages/dashboard/Dashboard";
import AdminPanelLayout from "../components/admin-panel/admin-panel-layout";
import AffiliateLinks from "pages/affiliate-links/AffiliateLinks";
import { Faq } from "pages/faq/Faq";
import Login from "pages/authentication/Login";
import ContactUs from "pages/contact-us/ContactUs";
import PrivacyPolicy from "pages/privacy-policy/PrivacyPolicy";
import TermsAndCondition from "pages/terms-and-condition/TermsAndCondition";

const SuspenseLoader = (Component) => (props) =>
  (
    <React.Suspense fallback={<withLoader />}>
      <Component {...props} />
    </React.Suspense>
  );

const Home = SuspenseLoader(
  lazy(() => import("../components/admin-panel/admin-panel-layout"))
);
function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}
const routes = (isLoggedin) => [
  {
    path: "/",
    element: isLoggedin ? (
      <AdminPanelLayout />
    ) : (
      <RedirectionWrapper to="/login" />
    ),
    children: [
      {
        element: <RedirectionWrapper to="/dashboard" />,
        index: true,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/affiliate-links",
        element: <AffiliateLinks />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-and-condition",
        element: <TermsAndCondition />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedin ? <Login /> : <RedirectionWrapper to="/dashboard" />,
  },
];

// export default function Router() {
//   return useRoutes(routes());
// }
export default function Routes(props) {
  const { isLoggedin } = props;
  return useRoutes(routes(isLoggedin));
}
