import React, { useEffect, useState } from "react";
import { ContentLayout } from "../../components/admin-panel/content-layout";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Trash2 } from "lucide-react";
import { PAGE_SIZE } from "../../components/common";
import { CustomPagination } from "../../components/CustomPagination";
import ContactUsService from "service/contact.service";
import Loader from "../../assets/images/loader.gif";

const ContactUs = () => {
  const [data, setData] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const getAllContactForm = async () => {
    setIsLoading(true);
    try {
      const response = await ContactUsService.getAllCOntactUsForms(
        page,
        PAGE_SIZE
      );
      setData(response.data.findContactUs);
      setTotalUser(response.data.totalContactUs);
      console.log("response", response.data.findContactUs);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllContactForm();
  }, []);

  const handleChange = (type, page) => {
    const total = totalUser / PAGE_SIZE;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  return (
    <ContentLayout title="Contactez-nous">
      <>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-12 p-0 text-center">No.</TableHead>
              <TableHead>Prénom</TableHead>
              <TableHead>Nom de famille</TableHead>
              <TableHead>E-mail</TableHead>
              <TableHead>Message</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} className="text-center">
                  <div className="flex justify-center items-center h-full">
                    <img src={Loader} alt="Loading..." className="w-12 h-12" />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {data.length > 0 ? (
                  data?.map((invoice, index) => (
                    <TableRow key={invoice.invoice}>
                      <TableCell className="font-medium text-center p-0">
                        {(page - 1) * PAGE_SIZE + index + 1}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {invoice.firstName}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {invoice.lastName}
                      </TableCell>
                      <TableCell className="whitespace-nowrap">
                        {invoice.email}
                      </TableCell>
                      <TableCell>{invoice.message}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableCell colSpan={5} className="text-center h-14">
                    Data Not Found
                  </TableCell>
                )}
              </>
            )}
          </TableBody>
        </Table>

        {data.length > 0 && (
          <div className="mt-5">
            <CustomPagination
              total={totalUser}
              page={page}
              dataPerPage={PAGE_SIZE}
              handleChange={handleChange}
            />
          </div>
        )}
      </>
    </ContentLayout>
  );
};

export default ContactUs;
