import React, { useState } from "react";
import { CustomPagination } from "../../components/CustomPagination";
import { Button } from "../../components/ui/button";
import { Card } from "../../components/ui/card";
import { PAGE_SIZE } from "../../components/common";
import Loader from "../../assets/images/loader.gif";

export function AffiliateDataTable({
  partners,
  setEditingPartner,
  handleModalOpen,
  handleDeleteOpen,
  totalCount,
}) {
  const [page, setPage] = useState(1);

  const handleEdit = (partner) => {
    console.log("🚀  handleEdit  partner:", partner);
    setEditingPartner(partner);
    handleModalOpen(partner, "Partners");
  };

  const handleChange = (type, page) => {
    const total = totalCount / PAGE_SIZE;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  return (
    <>
      {partners.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 xxl:grid-cols-4 gap-4">
          {partners.map((partner) => (
            <>
              {partner.investorsType === "Partners" && (
                <Card className="grid gap-4" key={partner.id}>
                  <div className="flex items-center justify-center">
                    <div className="h-12 w-12 rounded-sm overflow-hidden">
                      <img
                        src={partner.partnerImage}
                        alt="Logo"
                        className="object-cover bg-center h-full w-full"
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="scroll-m-20 text-lg font-semibold tracking-tight">
                      {partner.title}
                    </h3>
                    <p className="text-xs line-clamp-3">
                      {partner.description}
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <Button
                      className="w-full flex gap-2 px-0"
                      onClick={() => handleEdit(partner)}
                    >
                      Modifier
                    </Button>
                    <Button
                      variant="destructive"
                      className="w-full flex gap-2 px-0"
                      onClick={() => handleDeleteOpen(partner)}
                    >
                      Supprimer
                    </Button>
                  </div>
                </Card>
              )}
            </>
          ))}
        </div>
      ) : (
        <div className="flex justify-center mt-5">
          <p className="text-lg">Data Not Found</p>
        </div>
      )}
      {partners.length > 0 && (
        <div className="mt-5">
          <CustomPagination
            total={totalCount}
            page={page}
            pageSize={PAGE_SIZE}
            handleChange={handleChange}
          />
        </div>
      )}
    </>
  );
}
