import React, { useEffect, useState } from "react";
import { Card, CardDescription, CardTitle } from "../../components/ui/card";
import { ContentLayout } from "../../components/admin-panel/content-layout";
import { PiEyeThin } from "react-icons/pi";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import UserDataTable from "./card-data/UserDataTable";
import MostFrequentlyQuestions from "./card-data/MostFrequentlyQuestions";
import { Button } from "../../components/ui/button";
import { useFormik } from "formik";
import * as yup from "yup";
import Toaster from "../../components/ui/toaster";
import FAQService from "service/faq.service";
import DeleteModel from "../../components/ui/delete-model";
import { PAGE_SIZE } from "../../components/common";
import UtilisatricevService from "service/utilisatrice.service";
import Loader from "../../assets/images/loader.gif";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const { token } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [info, setInfo] = useState(null);
  const [tab, setTab] = useState("UserList");
  const [faqData, setFaqData] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [totalFAQ, setTotalFAQ] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [timeFilter, setTimeFilter] = useState("All");
  const [totalUser, setTotalUser] = useState(0);
  const [invoices, setInvoices] = useState([]);

  const getAllGPTFaq = async () => {
    try {
      const response = await UtilisatricevService.getAllGPTFAQ(
        page,
        PAGE_SIZE,
        timeFilter,
        token
      );
      setTotalFAQ(response?.data.mostFrequentlyData.totalQuestion);
      setFaqData(response.data.mostFrequentlyData.questionData);
      setIsLoading(false);
      // setTimeFilter("All");
    } catch (error) {
      Toaster("error", error?.response?.data.message);
    }
  };

  useEffect(() => {
    getAllGPTFaq();
  }, [page]);

  useEffect(() => {
    if (timeFilter) {
      getAllGPTFaq();
    }
  }, [timeFilter]);

  const formik = useFormik({
    initialValues: {
      question: "",
    },
    validationSchema: yup.object().shape({
      question: yup.string().required("Please enter Question"),
    }),
    onSubmit: async (values) => {
      try {
        const { id } = values;

        if (!id) {
          const response = await FAQService.createGPTFAQ(values);
          Toaster("success", response.data.message);
        } else {
          const response = await FAQService.updateGPTFAQ(id, values);
          Toaster("success", response.data.message);
        }
        handleClose();
        // getAllGPTFaq();
      } catch (error) {
        Toaster("error", error?.response?.data.message);
      }
    },
  });

  const handleDelete = async (id) => {
    setDeleteLoading(true);
    try {
      const response = await UtilisatricevService.deleteUser(id);
      Toaster("success", response.data.message);
      setIsDeleteOpen(false);
      setDetails([]);
      window.location.reload();
    } catch (error) {
      console.log("error", error);
      Toaster("error", error?.response?.data.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleModalOpen = async (result) => {
    setIsOpen(true);
    if (result) {
      formik.setFieldValue("id", result._id);
      formik.setFieldValue("question", result.question);
    } else {
      formik.resetForm();
    }
  };

  const handleDeleteOpen = (data) => {
    setIsDeleteOpen(true);
    setDetails(data);
  };

  const handleDeleteClose = () => {
    setIsDeleteOpen(false);
    setDetails([]);
  };

  const handleClose = () => {
    formik.resetForm();
    setIsOpen(false);
  };

  const handleChange = (type, page) => {
    const total = totalFAQ / PAGE_SIZE;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const handleTabChange = (tab) => {
    setTab(tab);
  };

  // tab 1

  const getAllUtilisatricev = async () => {
    setIsLoading(true);
    try {
      const response = await UtilisatricevService?.getAllUtilisatrice(
        page,
        PAGE_SIZE,
        timeFilter,
        token
      );

      if (response?.data?.data) {
        setInvoices(response.data.data || []);
        setTotalUser(response.data?.totalUsers || 0);
        setInfo({
          totalUsers: response.data.totalUsers,
          gptApiCnt: response.data.gptApiCnt,
        });
      }
    } catch (error) {
      Toaster("error", error?.response?.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUtilisatricev();
  }, [page, PAGE_SIZE, timeFilter]);

  const handleTypeChange = (value) => {
    setIsOpen(false);
    setTimeFilter(value);
  };

  return (
    <ContentLayout title="Tableau de bord">
      {isLoading ? (
        <div className="flex justify-center items-center h-[calc(100vh-176px)]">
          <img src={Loader} alt="Loader" className="w-20" />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 tab:grid-cols-3 lg:grid-cols-5 gap-4 items-center">
            <Card className="w-full">
              <CardDescription>No. d'utilisateurs</CardDescription>
              <div className="flex gap-3 items-center py-3">
                <CardTitle>{info?.totalUsers}</CardTitle>
              </div>
            </Card>
            {/* <Card className="w-full">
          <CardDescription>Sessions actives</CardDescription>
          <div className="flex gap-3 items-center py-3">
            <CardTitle>74</CardTitle>
          </div>
        </Card>
        <Card className="w-full">
          <CardDescription>Option utilisée</CardDescription>
          <div className="flex gap-3 items-center py-3">
            <CardTitle>520</CardTitle>
          </div>
        </Card>
        <Card className="w-full">
          <CardDescription>Nombre de requêtes API</CardDescription>
          <div className="flex gap-3 items-center py-3">
            <CardTitle>49</CardTitle>
          </div>
        </Card> */}
            <Card className="w-full">
              <CardDescription>Questions les plus fréquentes</CardDescription>
              <div className="flex gap-3 items-center py-3">
                <CardTitle>{totalFAQ}</CardTitle>
              </div>
            </Card>
          </div>

          <Tabs defaultValue="user" className="mt-5">
            <TabsList className="inline sm:flex justify-start w-full gap-4">
              <TabsTrigger
                value="user"
                className="p-4 w-full sm:w-auto"
                onClick={() => handleTabChange("UserList")}
              >
                Utilisatrice
              </TabsTrigger>
              <TabsTrigger
                value="questions"
                className="p-4 mt-4 sm:mt-0 w-full sm:w-auto"
                onClick={() => handleTabChange("MostFrequentlyQuestion")}
              >
                Questions fréquentes
              </TabsTrigger>
              {/* {tab === "MostFrequentlyQuestion" && (
                <div className="w-full flex justify-end">
                  <Button
                    className="sm:max-w-32 w-full mt-4 sm:mt-0"
                    onClick={() => handleModalOpen("")}
                  >
                    + Ajouter
                  </Button>
                </div>
              )} */}
            </TabsList>
            <TabsContent value="user" className="w-full">
              <Card>
                <UserDataTable
                  setInfo={setInfo}
                  handleDeleteOpen={handleDeleteOpen}
                  token={token}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                  page={page}
                  totalUser={totalUser}
                  invoices={invoices}
                  timeFilter={timeFilter}
                  isLoading={isLoading}
                  handleTypeChange={handleTypeChange}
                  handleChange={handleChange}
                />
              </Card>
            </TabsContent>
            <TabsContent value="questions">
              <MostFrequentlyQuestions
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                handleModalOpen={handleModalOpen}
                faqData={faqData}
                formik={formik}
                handleClose={handleClose}
                handleDeleteOpen={handleDeleteOpen}
                details={details}
                page={page}
                pageSize={PAGE_SIZE}
                handleChange={handleChange}
                totalFAQ={totalFAQ}
                setTimeFilter={setTimeFilter}
              />
            </TabsContent>
          </Tabs>

          {/* <UserDataTable
            setInfo={setInfo}
            handleDeleteOpen={handleDeleteOpen}
            token={token}
          /> */}

          {isDeleteOpen && (
            <DeleteModel
              isDeleteOpen={isDeleteOpen}
              setIsDeleteOpen={setIsDeleteOpen}
              handleDeleteClose={handleDeleteClose}
              details={details}
              handleDelete={handleDelete}
              deleteLoading={deleteLoading}
              type="user"
            />
          )}
        </>
      )}
    </ContentLayout>
  );
};

export default Dashboard;
