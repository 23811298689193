import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../components/ui/pagination";

export function CustomPagination({ total, page, handleChange, dataPerPage }) {
  const totalPages = Math.ceil(total / dataPerPage);

  const pageNumbers = [];

  pageNumbers.push(1);

  if (page > 2) {
    pageNumbers.push("...");
  }

  if (page !== 1 && page !== totalPages) {
    pageNumbers.push(page);
  }

  if (page < totalPages - 1) {
    pageNumbers.push("...");
  }

  if (totalPages > 1) {
    pageNumbers.push(totalPages);
  }

  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem
          onClick={() => handleChange("Previous", page)}
          className="cursor-pointer"
        >
          <PaginationPrevious />
        </PaginationItem>

        {pageNumbers.map((pageNumber, index) => (
          <li key={index}>
            {pageNumber === "..." ? (
              <PaginationEllipsis />
            ) : (
              <PaginationLink
                className="cursor-pointer"
                onClick={() => handleChange("", pageNumber)}
                isActive={page === pageNumber}
              >
                {pageNumber}
              </PaginationLink>
            )}
          </li>
        ))}

        <PaginationItem
          onClick={() => handleChange("Next", page)}
          className="cursor-pointer"
        >
          <PaginationNext />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
}
