import axios from "axios";
import config from "router/config";

const BASE_URL = config.baseApi;

const prosperityString = localStorage.getItem("prosperity-store");
const prosperityStore = JSON.parse(prosperityString);

const ServerCall = () => {
  const customAxios = axios.create({
    baseURL: BASE_URL,
  });

  const requestHandler = (request) => {
    if (prosperityStore && prosperityStore.token) {
      request.headers.Authorization = `Bearer ${prosperityStore.token}`;
    }

    return request;
  };

  const responseHandler = (response) => {
    if (response.status === 401 || response.status === 403) {
      // localStorage.clear();
      // localStorage.removeItem("token");
    }
    return response;
  };

  const requestErrorHandler = (error) => {
    return Promise.reject(error);
  };

  const responseErrorHandler = async (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        // localStorage.clear();
        // window.location = process.env.REACT_APP_HOME_PAGE;
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  };

  customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => requestErrorHandler(error)
  );

  customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    responseErrorHandler
  );
  return { customAxios };
};

export default ServerCall;
