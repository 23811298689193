import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const PrivacyPolicy = () => {
  return (
    <div className="w-full max-w-2xl mx-auto p-4 space-y-4">
      <ReactQuill
        theme="snow"
        // value={params.description || ""}
        // defaultValue={params.description || ""}
        // onChange={(content, delta, source, editor) => {
        //   params.description = content;
        //   params.displayDescription = editor.getText();
        //   setParams({
        //     ...params,
        //   });
        // }}
        style={{ minHeight: "200px" }}
      />
    </div>
  );
};
export default PrivacyPolicy;
