import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Label } from "../../components/ui/label";
import { Textarea } from "../../components/ui/textarea";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import Loader from "../../assets/images/loader.gif";

const PartnerModal = ({
  isOpen,
  setIsOpen,
  partner,
  onSave,
  tab,
  formik,
  handleClose,
  handleImageChange,
  selectedImage,
  submitLoading,
}) => {
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-[425px]" onClose={handleClose}>
        <DialogHeader>
          <DialogTitle>{partner ? "Edit Partner" : "Add Partner"}</DialogTitle>
        </DialogHeader>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid gap-4 py-4">
            <div className="flex items-center justify-center flex-col gap-2">
              <div
                className="h-20 w-20 rounded-sm overflow-hidden cursor-pointer"
                onClick={handleImageClick}
              >
                <img
                  src={selectedImage}
                  alt="User"
                  className="object-cover bg-center w-full h-full"
                />
              </div>
              <p className="cursor-pointer" onClick={handleImageClick}>
                Change Image
              </p>
              <input
                ref={fileInputRef}
                type="file"
                className="hidden"
                accept="image/png, image/jpeg"
                onChange={handleImageChange}
              />
              {formik.errors.file && formik.touched.file && (
                <p className="valierror">{formik.errors.file}</p>
              )}
            </div>

            {tab === 1 && (
              <>
                <div className="grid gap-4">
                  <div className="grid gap-2">
                    <Label htmlFor="name">Name</Label>
                    <Input
                      id="name"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      placeholder="Name..."
                      name="title"
                    />
                    {formik.errors.title && formik.touched.title && (
                      <p className="valierror">{formik.errors.title}</p>
                    )}
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor="content">Content</Label>
                    <Textarea
                      id="content"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      placeholder="Type your content here."
                      name="description"
                    />
                    {formik.errors.description &&
                      formik.touched.description && (
                        <p className="valierror">{formik.errors.description}</p>
                      )}
                  </div>
                </div>
              </>
            )}
            {tab === 2 && (
              <>
                <Label htmlFor="title">Title</Label>
                <Input
                  id="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  placeholder="Name..."
                  name="title"
                />
                {formik.errors.title && formik.touched.title && (
                  <p className="valierror">{formik.errors.title}</p>
                )}
              </>
            )}
            <div className="grid gap-2">
              <Label htmlFor="urlLink">Video Url</Label>
              <Input
                id="urlLink"
                value={formik.values.urlLink}
                onChange={formik.handleChange}
                placeholder="Enter Video URL here."
                name="urlLink"
              />
              {formik.errors.urlLink && formik.touched.urlLink && (
                <p className="valierror">{formik.errors.urlLink}</p>
              )}
            </div>

            {tab === 1 && (
              <div className="grid gap-2">
                <Label htmlFor="urlLink">Category</Label>
                <Input
                  id="partnerCategory"
                  value={formik.values.partnerCategory}
                  onChange={formik.handleChange}
                  placeholder="Enter Category"
                  name="partnerCategory"
                />
                {formik.errors.partnerCategory &&
                  formik.touched.partnerCategory && (
                    <p className="valierror">{formik.errors.partnerCategory}</p>
                  )}
              </div>
            )}
          </div>
          <DialogFooter>
            <Button
              type="button"
              variant="destructive"
              onClick={handleClose}
              className="w-full"
            >
              Cancel
            </Button>
            <Button type="submit" className="w-full">
              {submitLoading ? (
                <img src={Loader} alt="Loader" className="w-7" />
              ) : (
                "Save"
              )}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PartnerModal;
