import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import Router from "./router/router";
import { ThemeProvider } from "next-themes";
import { useNavigate } from "react-router";
import { LocalData } from "./components/common";
import { setLoggedIn, setToken } from "store/slice/auth";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const localData = LocalData();

  useEffect(() => {
    const token = localData?.token;
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (token) {
      dispatch(setToken(token));
    }

    if (isLoggedIn) {
      dispatch(setLoggedIn(JSON.parse(isLoggedIn)));
    }
  }, [dispatch]);

  const { isLoggedIn } = useSelector((state) => state.auth);

  const isLoggedin =
    isLoggedIn || !!JSON.parse(localData !== null && localData?.token);

  return (
    <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
      <Router isLoggedin={isLoggedin} />
    </ThemeProvider>
  );
}

export default App;
