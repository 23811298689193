import { get } from "lodash";
import Toaster from "../components/ui/toaster";
import ServerCall from "serverCall";

const serverCall = ServerCall();
const login = async (userSignInRequest) => {
  const { email, password } = userSignInRequest;

  try {
    const response = await serverCall.customAxios.post(
      `/admin/login?email=${email}&password=${password}`
    );
    const data = get(response, "data", null);

    console.log("data", data);
    if (data.data.isAdmin) {
      if (data) {
        const email = data.data.email;
        const id = data.data._id;
        const token = data.token;

        const userProfile = {
          token,
          email,
          id,
        };
        localStorage.setItem("prosperity-store", JSON.stringify(userProfile));
      }
    } else {
      Toaster("error", `Admin not found with this email ${email}`);
    }
    return response;
  } catch (error) {
    Toaster("error", error?.response?.data.message);
  }
};

const AuthenticationService = {
  login,
};

export default AuthenticationService;
