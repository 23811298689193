import React, { useEffect, useState } from "react";
import { CustomPagination } from "../../../components/CustomPagination";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import Toaster from "../../../components/ui/toaster";
import { PAGE_SIZE } from "../../../components/common";
import UtilisatricevService from "service/utilisatrice.service";
import { ChevronDownIcon, Trash2 } from "lucide-react";
import { Card } from "../../../components/ui/card";
import Loader from "../../../assets/images/loader.gif";

const filterData = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "1 Day",
    value: "1 Day",
  },
  {
    label: "1 Month",
    value: "1 Month",
  },
  {
    label: "3 Month",
    value: "3 Month",
  },
  {
    label: "6 Month",
    value: "6 Month",
  },
  {
    label: "1 Year",
    value: "1 Year",
  },
];

const UserDataTable = ({
  setInfo,
  handleDeleteOpen,
  token,
  setIsOpen,
  isOpen,
  page,
  totalUser,
  invoices,
  timeFilter,
  isLoading,
  handleTypeChange,
  handleChange,
}) => {
  // const [page, setPage] = useState(1);
  // const [totalUser, setTotalUser] = useState(0);
  // const [invoices, setInvoices] = useState([]);
  // const [timeFilter, setTimeFilter] = useState("All");
  // // const [isOpen, setIsOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="mt-5">
      <div className="flex justify-between">
        <div className="my-3">
          <p>Utilisateurs</p>
        </div>

        <div className="mt-1 mb-5 relative flex justify-end">
          <button
            type="button"
            className="relative w-[150px] border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="block truncate">{timeFilter}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </button>

          {isOpen && (
            <ul
              className="absolute z-10 mt-10 w-[150px] bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              {filterData.map((item, index) => (
                <li
                  key={index}
                  className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white"
                  id="listbox-option-0"
                  role="option"
                  onClick={() => handleTypeChange(item.value)}
                >
                  <span className="font-normal block truncate">
                    {item.value}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-12 p-0 text-center">No.</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>API Count</TableHead>
              <TableHead className="text-center w-28">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={4} className="text-center">
                  <div className="flex justify-center items-center h-full">
                    <img src={Loader} alt="Loading..." className="w-12 h-12" />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {invoices?.map((invoice, index) => (
                  <TableRow key={invoice.invoice}>
                    <TableCell className="font-medium text-center p-0">
                      {(page - 1) * PAGE_SIZE + index + 1}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {invoice.email ? invoice.email : "--"}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {invoice.apiCountData}
                    </TableCell>
                    <TableCell className="w-28">
                      <div className="flex items-center justify-center gap-3">
                        <div
                          className="cursor-pointer border border-red-600 rounded p-1 hover:bg-red-600 group duration-300"
                          onClick={() =>
                            handleDeleteOpen(invoice, "Utilisatrice")
                          }
                        >
                          <Trash2 className="h-4 w-4 text-red-600 group-hover:text-white duration-300" />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
        <div className="mt-5">
          <CustomPagination
            total={totalUser}
            page={page}
            dataPerPage={PAGE_SIZE}
            handleChange={handleChange}
          />
        </div>
      </Card>
    </div>
  );
};

export default UserDataTable;
