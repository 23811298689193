import ServerCall from "serverCall";

const serverCall = ServerCall();

const getAllLinks = (investorsType, page, limit) => {
  const response = serverCall.customAxios.get(
    `/investisseurs/getAll?investorsType=${investorsType}&page=${page}&limit=${limit}`
  );
  return response;
};

const createInvestIsseurs = (body) => {
  const response = serverCall.customAxios.post(`/investisseurs/create`, body);
  return response;
};

const updateInvestIsseurs = (investorsId, investorsType, body) => {
  const response = serverCall.customAxios.patch(
    `/investisseurs/update?investorsId=${investorsId}&investorsType=${investorsType}`,
    body
  );
  return response;
};

const getById = (investorsId, investorsType) => {
  const response = serverCall.customAxios.get(
    `/investisseurs/getById?investorsId=${investorsId}&investorsType=${investorsType}`
  );
  return response;
};

const deleteById = (investorsId, investorsType) => {
  const response = serverCall.customAxios.delete(
    `/investisseurs/delete?investorsId=${investorsId}&investorsType=${investorsType}`
  );
  return response;
};

const AffiliateLinksService = {
  getAllLinks,
  createInvestIsseurs,
  getById,
  updateInvestIsseurs,
  deleteById,
};

export default AffiliateLinksService;
