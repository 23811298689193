import { useState } from "react";
import { CustomPagination } from "../../components/CustomPagination";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Pencil } from "lucide-react";
import { Trash2 } from "lucide-react";
import { PAGE_SIZE } from "../../components/common";
import Loader from "../../assets/images/loader.gif";

export function AffiliateVideoDataTable({
  data,
  handleModalOpen,
  handleDeleteOpen,
  totalCount,
  isTableLoading,
}) {
  const [page, setPage] = useState(1);

  const handleChange = (type, page) => {
    const total = totalCount / PAGE_SIZE;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-12 p-0 text-center">No.</TableHead>
            <TableHead>Image miniature</TableHead>
            <TableHead>Liens d'affiliation</TableHead>
            <TableHead className="text-center w-28">Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isTableLoading ? (
            <TableRow>
              <TableCell colSpan={4} className="text-center">
                <div className="flex justify-center items-center h-full">
                  <img src={Loader} alt="Loading..." className="w-12 h-12" />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="font-medium text-center p-0">
                      {(page - 1) * PAGE_SIZE + index + 1}
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      <img
                        src={item.videoThumbnail}
                        className="w-16 h-10 object-cover"
                      />
                    </TableCell>

                    <TableCell className="whitespace-nowrap">
                      {item.urlLink ? item.urlLink : "--"}
                    </TableCell>
                    <TableCell className="w-28">
                      <div className="flex items-center justify-center gap-3">
                        <div
                          className="cursor-pointer border border-black rounded p-1 hover:bg-black group duration-300 dark:border-white"
                          onClick={() => handleModalOpen(item, "Video")}
                        >
                          <Pencil className="h-4 w-4 text-black group-hover:text-white duration-300 dark:text-white" />
                        </div>
                        <div
                          className="cursor-pointer border border-red-600 rounded p-1 hover:bg-red-600 group duration-300"
                          onClick={() => handleDeleteOpen(item)}
                        >
                          <Trash2 className="h-4 w-4 text-red-600 group-hover:text-white duration-300" />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell className="text-center h-14" colSpan={4}>
                  Data Not Found
                </TableCell>
              )}
            </>
          )}
        </TableBody>
      </Table>
      {data.length > 0 && (
        <div className="mt-5">
          <CustomPagination
            total={totalCount}
            page={page}
            pageSize={PAGE_SIZE}
            handleChange={handleChange}
          />
        </div>
      )}
    </>
  );
}
