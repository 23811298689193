import ServerCall from "serverCall";

const serverCall = ServerCall();

const getAllCOntactUsForms = (page, limit) => {
  const response = serverCall.customAxios.get(
    `/admin/contactUs/read?page=${page}&limit=${limit}`
  );
  return response;
};

const ContactUsService = {
  getAllCOntactUsForms,
};

export default ContactUsService;
