import ServerCall from "serverCall";

const serverCall = ServerCall();

const getAllFaq = () => {
  const response = serverCall.customAxios.get(`/faq/getAll`);
  return response;
};

const deleteFaq = (id) => {
  const response = serverCall.customAxios.delete(`/faq/delete?faqId=${id}`);
  return response;
};

const createFAQ = (body) => {
  const response = serverCall.customAxios.post(`/faq/createFAQ`, body);
  return response;
};

const getAllGPTFaq = (page, limit, token) => {
  const response = serverCall.customAxios.get(
    `/faq/getAllGptFAQ?page=${page}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

const createGPTFAQ = (body) => {
  const response = serverCall.customAxios.post(`/faq/createGptFAQ`, body);
  return response;
};

const updateGPTFAQ = (gptFaqId, body) => {
  const response = serverCall.customAxios.post(
    `/faq/updateGptFAQ?gptFaqId=${gptFaqId}`,
    body
  );
  return response;
};

const deleteGPTFaq = (id) => {
  const response = serverCall.customAxios.delete(
    `/faq/deleteGptFAQ?gptFaqId=${id}`
  );
  return response;
};

const FAQService = {
  getAllFaq,
  createFAQ,
  deleteFaq,
  getAllGPTFaq,
  createGPTFAQ,
  updateGPTFAQ,
  deleteGPTFaq,
};

export default FAQService;
