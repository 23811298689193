import React from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "./button";
import Loader from "../../assets/images/loader.gif";

const DeleteModel = ({
  isDeleteOpen,
  setIsDeleteOpen,
  handleDeleteClose,
  details,
  handleDelete,
  type,
  deleteLoading,
}) => {
  console.log(details);
  return (
    <Dialog open={isDeleteOpen} onOpenChange={setIsDeleteOpen}>
      <DialogContent className="sm:max-w-[425px]" onClose={handleDeleteClose}>
        <DialogHeader>
          <DialogTitle>Delete</DialogTitle>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <p>Are you sure want to delete this {type}?</p>
        </div>

        <DialogFooter>
          <Button
            type="button"
            variant="destructive"
            onClick={handleDeleteClose}
            className="w-full"
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => {
              if (type === "user") {
                handleDelete(details.email);
              } else {
                handleDelete(details._id, details.investorsType);
              }
            }}
            className="w-full"
          >
            {deleteLoading ? (
              <img src={Loader} alt="Loader" className="w-7" />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteModel;
