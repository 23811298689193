import React, { useState } from "react";
import { CustomPagination } from "../../../components/CustomPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Button } from "../../../components/ui/button";
import { Card } from "../../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Label } from "../../../components/ui/label";
import { Textarea } from "../../../components/ui/textarea";
import { MdOutlineRefresh } from "react-icons/md";

const MostFrequentlyQuestions = ({
  isOpen,
  setIsOpen,
  handleModalOpen,
  faqData,
  formik,
  handleClose,
  handleDeleteOpen,
  page,
  pageSize,
  handleChange,
  totalFAQ,
  setTimeFilter,
}) => {
  return (
    <>
      {/* Dialog */}
      <div className="flex justify-end">
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Add Most Frequently Questions</DialogTitle>
            </DialogHeader>
            <form onSubmit={formik.handleSubmit}>
              <div className="grid gap-4 py-4">
                <div className="flex flex-col gap-2">
                  <Label htmlFor="content">Question Content</Label>
                  <Textarea
                    id="question"
                    value={formik.values.question}
                    onChange={formik.handleChange}
                    placeholder="Type your question content here."
                    name="question"
                  />
                </div>
              </div>
              <DialogFooter>
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleClose}
                  className="w-full"
                >
                  Cancel
                </Button>
                <Button type="submit" className="w-full">
                  Save
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>
      </div>

      {/* Table */}
      {faqData != "" ? (
        <Card>
          <div className="flex justify-end">
            <MdOutlineRefresh
              className="cursor-pointer border rounded p-1 h-8 w-8 hover:text-gray-300"
              onClick={() => setTimeFilter(true)}
            />
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-12 p-0 text-center">No.</TableHead>
                <TableHead>Affiliate Links</TableHead>
                {/* <TableHead className="text-center w-28">Action</TableHead> */}
              </TableRow>
            </TableHeader>
            <TableBody>
              {faqData.map((question, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium text-center p-0">
                    {index + 1}
                    {/* {(page - 1) * pageSize + (index + 1)} */}
                  </TableCell>
                  <TableCell className="min-w-[935px]">
                    <div>
                      <p className="text-sm">
                        <span className="font-bold">Category</span>:{" "}
                        {question.category}
                      </p>
                      <p className="mt-1">
                        <span className="font-bold">Question</span>:{" "}
                        {question.question}
                      </p>
                    </div>
                  </TableCell>
                  {/*  <TableCell className="w-28">
                  <div className="flex items-center justify-center gap-3">
                    <div
                      className="cursor-pointer border border-black rounded p-1 hover:bg-black group duration-300 dark:border-white"
                      onClick={() => handleModalOpen(question)}
                    >
                      <Pencil className="h-4 w-4 text-black group-hover:text-white duration-300 dark:text-white" />
                    </div>
                    <div
                      className="cursor-pointer border border-red-600 rounded p-1 hover:bg-red-600 group duration-300"
                      onClick={() => handleDeleteOpen(question,"faq")}
                    >
                      <Trash2 className="h-4 w-4 text-red-600 group-hover:text-white duration-300" />
                    </div>
                  </div>
                </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      ) : (
        <div>FAQ Not Found</div>
      )}
    </>
  );
};

export default MostFrequentlyQuestions;
