import React, { useEffect, useState } from "react";
import { Button } from "../../components/ui/button";
import { ContentLayout } from "../../components/admin-panel/content-layout";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Trash2 } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import Toaster from "../../components/ui/toaster";
import FAQService from "service/faq.service";
import { useFormik } from "formik";
import * as yup from "yup";
import DeleteModel from "../../components/ui/delete-model";
import Loader from "../../assets/images/loader.gif";

export function Faq() {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const deleteFaqItem = (id) => {
    setIsDeleteOpen(true);
    setDetails(id);
  };

  const submit = async (values) => {
    const { question, answer } = values;
    setSubmitLoading(true);
    try {
      const body = {
        question,
        answer,
      };
      const response = await FAQService.createFAQ(body);
      setIsOpen(false);
      getAllFaq();
      Toaster("success", response.data.message);
    } catch (error) {
      console.log(error);
      Toaster("error", error?.response?.data.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    question: yup.string().required("Question is required"),
    answer: yup.string().required("Answer is required"),
  });

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema: validationSchema,
    onSubmit: submit,
  });

  const getAllFaq = async () => {
    try {
      const response = await FAQService.getAllFaq();
      setData(response.data.allFAQ);
    } catch (error) {
      Toaster("error", error?.response?.data.message);
    }
  };

  useEffect(() => {
    getAllFaq();
  }, []);

  const handleDeleteClose = () => {
    setIsDeleteOpen(false);
    setDetails([]);
  };

  const handleDelete = async (id) => {
    setDeleteLoading(true);
    try {
      const response = await FAQService.deleteFaq(id);
      Toaster("success", response.data.message);
      getAllFaq();
      setIsDeleteOpen(false);
      setDetails([]);
    } catch (error) {
      Toaster("error", error?.response?.data.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <ContentLayout title="Questions fréquemment posées">
        <div className="flex justify-end mb-4">
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
              <Button className="max-w-32 w-full">+ Ajouter</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>Add FAQ Question</DialogTitle>
              </DialogHeader>
              <form onSubmit={formik.handleSubmit}>
                <div className="grid gap-4 py-4">
                  <div className="flex flex-col gap-2">
                    <Label htmlFor="question">Question</Label>
                    <Input
                      id="question"
                      value={formik.values.question}
                      onChange={formik.handleChange}
                      className="col-span-3"
                      name="question"
                    />
                    {formik.touched.question && formik.errors.question ? (
                      <div className="valierror">{formik.errors.question}</div>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-2">
                    <Label htmlFor="answer">Answer</Label>
                    <Textarea
                      id="answer"
                      value={formik.values.answer}
                      onChange={formik.handleChange}
                      placeholder="Type your answer here."
                      name="answer"
                    />{" "}
                    {formik.touched.answer && formik.errors.answer ? (
                      <div className="valierror">{formik.errors.answer}</div>
                    ) : null}
                  </div>
                </div>
                <DialogFooter>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setIsOpen(false)}
                    className="w-full"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="w-full">
                    {submitLoading ? (
                      <img src={Loader} alt="Loader" className="w-7" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
        </div>
        {data.length > 0 ? (
          <Accordion type="single" collapsible className="w-full">
            {data.map((item, index) => (
              <AccordionItem key={item._id} value={`item-${item._id}`}>
                <AccordionTrigger>
                  <div className="flex items-center justify-between w-full pr-4">
                    <span>
                      {index + 1}. {item.question}
                    </span>
                    <div
                      className="cursor-pointer hover:bg-red-600 group border-red-600 border rounded-sm p-1 duration-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteFaqItem(item);
                      }}
                    >
                      <Trash2 className="h-4 w-4 text-red-600 group-hover:text-white duration-300" />
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent>{item.answer}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <div className="text-center mt-6 text-xl">FAQ Not Found</div>
        )}
      </ContentLayout>

      {isDeleteOpen && (
        <DeleteModel
          isDeleteOpen={isDeleteOpen}
          setIsDeleteOpen={setIsDeleteOpen}
          handleDeleteClose={handleDeleteClose}
          details={details}
          handleDelete={handleDelete}
          type="faq"
          deleteLoading={deleteLoading}
        />
      )}
    </>
  );
}
