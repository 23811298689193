import ServerCall from "serverCall";

const serverCall = ServerCall();

const getAllUtilisatrice = (page, pageSize, timeFilter, token) => {
  try {
    const response = serverCall.customAxios.get(
      `/admin/dashboard?page=${page}&limit=${pageSize}&timeFilter=${timeFilter}&dashBoardType=UserList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
const getAllGPTFAQ = (page, pageSize, latestGptFAQ, token) => {
  try {
    const response = serverCall.customAxios.get(
      `/admin/dashboard?dashBoardType=MostFrequentlyQuestion&latestGptFAQ=${latestGptFAQ}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteUser = (email) => {
  try {
    const response = serverCall.customAxios.delete(
      `/admin/userDelete?email=${email}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const UtilisatricevService = {
  getAllUtilisatrice,
  deleteUser,
  getAllGPTFAQ,
};

export default UtilisatricevService;
